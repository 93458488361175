import { EditorState } from "draft-js";
import { BaseEmoji } from "ds/Emoji/src/types";
import { TextExtendedProps } from "grommet";
import React from "react";

import { Lease } from "graphql/generated/types";
import { TMessage } from "graphql/messages";
import { NumberType } from "graphqlQueries/generated/globalTypes";
import { ICard, IStickerCard, IUploadMedia, TCardAttachment, ValueOf } from "types";

export const ReplyType = {
  DEFAULT: "reply",
  HANDOVER: "reply-handover",
} as const;

export type TReplyType = ValueOf<typeof ReplyType>;

export type TActive = {
  active?: boolean;
  disabled?: boolean;
  greyOut?: boolean;
  fontFamily?: string;
};

interface IDraftControls {
  disabledCharacters?: boolean;
  disableEmoji?: boolean;
  showLink?: boolean;
  disableMarks?: boolean;
  editorState: EditorState;
  emojiId?: string;
  hideTotalEnd?: boolean;
  leftoverSymbols?: boolean;
  onSelectEmoji?: (emoji: BaseEmoji) => void;
  onAddLink?: (newEditorState: EditorState) => void;
  onToggle?: (type: string) => void;
}
export interface IReplyBoxEditorControls extends IDraftControls {
  attachment?: IUploadMedia | TCardAttachment;
  isOldMessage?: boolean;
  numberType?: NumberType;
  onAddAttachment: (attachment?: IUploadMedia | TCardAttachment) => void;
  onAddVoiceRecord: (attachment?: IUploadMedia | TCardAttachment) => void;
  onAddContent: (content: IStickerCard | ICard) => void;
  onRemoveAttachment: () => void;
  submit?: (e?: React.SyntheticEvent) => void;
  onFocus?: () => void;
  lease?: Lease | null;
  replyType?: string;
  setReplyType?: (replyType: TReplyType) => void;
  onSubmitMessage?: (message?: TMessage) => void;
  disabled?: boolean;
  aiSettingsEnabled?: boolean;
  onClickLanguageSettings?: () => void;
}
export interface IInlineStyleControls extends IDraftControls {
  charactersProps?: TextExtendedProps;
  disabled?: boolean;
  maxLength?: number;
  minWarningLength?: number;
  onAddVariable: () => void;
  variable?: boolean;
}
